import { analysisTypes, stockingPhaseTypes } from '../config/commons';

export const getStockingTypeFromAnalysis = (type: string) => {
  switch (type) {
    case analysisTypes.GENERAL_LARVAE_ANALYSIS:
      return stockingPhaseTypes.LARVAE;

    case analysisTypes.GENERAL_JUVENILE_ANALYSIS:
      return stockingPhaseTypes.JUVENILE;

    case analysisTypes.GENERAL_ADULT_ANALYSIS:
      return stockingPhaseTypes.ADULT;
  }
};
