import BarsChart from './BarsChart';
import styles from './Chart.module.scss';
import { Analysis } from './interfaces';
import { getUnitCountAnimalChartByAnalysisType, getUnitCountAnimalChartByStockingType } from './helpers';

interface Props {
  analysisData: Analysis;
  typeChart: 'GROW_OUT_WHOLE' | 'GROW_OUT_TAIL';
  axisClassName?: string;
  lineTop?: string;
  textClassName?: string;
  textInsideBarClassName?: string;
  containerClassName?: string;
  typeStyles?: 'DEFAULT' | 'PDF';
}

function GrowOutSizesChart (props: Props) {
  const {
    axisClassName = styles.axis,
    lineTop = styles.lineTop,
    textClassName = styles.chartText,
    textInsideBarClassName = styles.chartText,
    containerClassName = styles.container,
    analysisData,
    typeChart,
    typeStyles = 'DEFAULT',
  } = props;

  const larvaeNumber = analysisData.resultData.larvaeNumber;
  const analysisType = analysisData.type;
  const { tail, whole } = analysisData.growOutSizes;

  let data: number[] = [];
  let labels: string[] = [];
  let percents: string[] = [];

  const measureUnit = (analysisData.sowingId && analysisData.sowingId.phaseType) ?
    getUnitCountAnimalChartByStockingType(analysisData.sowingId.phaseType) :
    getUnitCountAnimalChartByAnalysisType(analysisType);
  const colorBars = typeChart === 'GROW_OUT_WHOLE' || typeChart === 'GROW_OUT_TAIL' ? '#696ffb' : undefined;

  const updateGrowOutSizesData = (growOutSizes: {
    values: number[];
    ranges: number[];
    labels: string[];
  }) => {
    data = growOutSizes.values.slice().reverse();
    percents = data.map(value => ((value * 100) / larvaeNumber).toFixed(1));
    labels = growOutSizes.labels.slice().reverse();
  };

  if (typeChart === 'GROW_OUT_WHOLE') {
    updateGrowOutSizesData(whole);
  } else if (typeChart === 'GROW_OUT_TAIL') {
    updateGrowOutSizesData(tail);
  }

  return (
    <BarsChart
      colorBars={colorBars}
      data={data}
      weightLabels={labels}
      measureUnit={measureUnit}
      isConsolidatedGrowOut={true}
      percents={percents}
      averageWeight={0}
      axisClassName={axisClassName}
      lineTop={lineTop}
      textClassName={textClassName}
      textInsideBarClassName={textInsideBarClassName}
      containerClassName={containerClassName}
      typeStyles={typeStyles}
    />
  );
}

export default GrowOutSizesChart;
