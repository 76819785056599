import { useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import { useTranslation } from 'react-i18next';

import { Store } from '../../state/store.interface';
import { formatLocaleDate, formatLocaleHour } from '../../utils/date';
import { getStockingTypeFromAnalysis } from '../../helpers/analysis.helpers';
import { roundWeight, roundLength, animalDaysUnit, DEFAULT_DAYS_TO_INITIAL_STAGE, stockingPhaseTypes, analysisTypes } from '../../config/commons';

import styles from './Data.module.scss';
import { getAverageGrowthOrLarvaePerGram, getAverageGrowthOrLarvaePerGramLabel } from './dataDetail.helpers';

export default function DataDetail () {
  const [t] = useTranslation();
  const { analysis: analysisOriginal } = useSelector((state: Store) => state.detail);

  const days = analysisOriginal.inputData?.stage + (DEFAULT_DAYS_TO_INITIAL_STAGE - 1);
  const stockingPhaseType = analysisOriginal.sowingId?.phaseType;
  const dictType = stockingPhaseType ?
    analysisOriginal.sowingId.phaseType === stockingPhaseTypes.LARVAE ? 'larvae' : 'juvenile' :
    analysisOriginal.type === analysisTypes.GENERAL_LARVAE_ANALYSIS ? 'larvae' : 'juvenile';

  const stage = stockingPhaseType ?
    analysisOriginal.sowingId.phaseType === stockingPhaseTypes.LARVAE ? `${animalDaysUnit.PL} ${analysisOriginal.inputData?.stage} - ${days} ${t('analysis.days')}` : `${analysisOriginal.inputData?.stage} ${t('analysis.days')}` :
    analysisOriginal.type === analysisTypes.GENERAL_LARVAE_ANALYSIS ? `${animalDaysUnit.PL} ${analysisOriginal.inputData?.stage} - ${days} ${t('analysis.days')}` : `${analysisOriginal.inputData?.stage} ${t('analysis.days')}`;
  const phaseType = stockingPhaseType || getStockingTypeFromAnalysis(analysisOriginal.type) || '';

  return <div className={styles.gridContainer}>
    <div>
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.resultData?.larvaeNumber} {t(`analysis.resultData.${dictType}Sample`)} / {analysisOriginal.inputData?.sampleWeight} g
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.sample')}
        </Text>
      </div>

      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {getAverageGrowthOrLarvaePerGram({
            phaseType: phaseType,
            averageWeight: analysisOriginal.resultData.averageWeight,
            stage: analysisOriginal.inputData.stage,
            larvaePerGram: analysisOriginal.resultData.larvaePerGram,
          })}
        </Text>
        <Text className={styles.field}>
          {getAverageGrowthOrLarvaePerGramLabel(phaseType)}
        </Text>
      </div>

      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {stage}
        </Text>
        <Text className={styles.field}>
          {t(`analysis.inputData.${dictType}Stage`)}
        </Text>
      </div>
    </div>

    <div>
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {roundWeight({ value: analysisOriginal.resultData?.averageWeight })}
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.averageWeight')}
        </Text>
      </div>

      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.resultData?.variationCoefficient.toFixed(2)} %
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.variationCoefficient')}
        </Text>
      </div>

      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.resultData?.uniformity.toFixed(2)} %
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.uniformity')}
        </Text>
      </div>
    </div>

    <div>
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {roundLength({ value: analysisOriginal.resultData?.averageLength })}
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.averageLength')}
        </Text>
      </div>

      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.resultData.variationCoefficientLength.toFixed(2)} %
        </Text>
        <Text className={styles.field}>
          {t('analysis.resultData.variationCoefficientLength')}
        </Text>
      </div>

      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.inputData.maturationId?.name || '-'}
        </Text>
        <Text className={styles.field}>
          {t('analysis.inputData.maturation')}
        </Text>
      </div>
    </div>

    <div>
      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {analysisOriginal.campusId?.name.toUpperCase() || '-'}
        </Text>
        <Text className={styles.field}>
          {t('analysis.campus')}
        </Text>
      </div>

      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {formatLocaleDate(analysisOriginal.createdAt)}
        </Text>
        <Text className={styles.field}>
          {t('analysis.createdAt')}
        </Text>
      </div>

      <div className={styles.gridItem}>
        <Text className={styles.value}>
          {formatLocaleHour(analysisOriginal.createdAt)}
        </Text>
        <Text className={styles.field}>
          {t('analysis.hour')}
        </Text>
      </div>
    </div>
  </div>;
}
