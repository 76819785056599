import cx from 'classnames';
import { Radio, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setBin } from './detailAnalysisSlice';
import { Store } from '../../state/store.interface';
import Icon from '../../common/components/Icon/Icon';
import { getHeight, getWidth, handleResizeEvent } from '../../utils/dimensions';
import { analysisTypes, roundOneDecimal, typeHistogram } from '../../config/commons';

import HistogramD3 from './HistogramD3';
import styles from './Histogram.module.scss';
import { Dataset, Frequency } from './interfaces';
import { getAverage, getDataset, getFrequency, typeStyles, getAverageOriginal, getLimits, getAnimalsAboveAverage, getAnimalsBelowAverage, getUnit } from './histogram.helpers';

let chart: HistogramD3 | null;

interface Props {
  showOptions?: boolean;
  minBin?: number;
  typeHistogramChart?: string;
  stylesHistogram?: string;
  containerClassName?: string;
  containerAnimalsClassName?: string;
  animalsClassName?: string;
}

export default function Histogram (props: Props) {
  const {
    showOptions = true,
    minBin = 6,
    typeHistogramChart = typeHistogram.WEIGHT,
    stylesHistogram = typeStyles.DEFAULT,
    containerClassName = styles.container,
    containerAnimalsClassName = styles.containerAnimals,
    animalsClassName = styles.animals,
  } = props;

  const dispatch = useDispatch();
  const [t] = useTranslation();
  const refChart = useRef<HTMLDivElement>(null);

  const [numberBins, setNumberBins] = useState(minBin);
  const [histogram, setHistogram] = useState(typeHistogramChart);
  const [animalsAboveAverage, setAnimalsAboveAverage] = useState(0);
  const [animalsBelowAverage, setAnimalsBelowAverage] = useState(0);
  const [percentageAnimalsAboveAverage, setPercentageAnimalsAboveAverage] = useState(0);
  const [percentageAnimalsBelowAverage, setPercentageAnimalsBelowAverage] = useState(0);

  const { analysis: analysisOriginal } = useSelector((state: Store) => state.detail);
  const dictType = analysisOriginal.type === analysisTypes.GENERAL_LARVAE_ANALYSIS ? 'larvae' : 'juvenile';

  useEffect(() => {
    dispatch(setBin(minBin));
    return () => {
      chart = null;
    };
  }, [dispatch, minBin]);

  useEffect(() => {
    handleResizeEvent(() => {
      const width = getWidth(refChart);
      const height = getHeight(refChart);

      chart && chart.resize(width, height);
    });
  }, []);

  const showAnimalsAboveBelowAverage = () => {
    return (histogram === typeHistogram.WEIGHT || histogram === typeHistogram.LENGTH) &&
      (analysisOriginal.resultData.animalsAboveAverageLength);
  };

  useEffect(() => {
    const frequency: Frequency = getFrequency(histogram, analysisOriginal, numberBins);

    const dataset: Dataset[] = getDataset(frequency);
    const averageOriginal = getAverageOriginal(histogram, analysisOriginal);
    const limits: number[] = getLimits(histogram, frequency, averageOriginal);

    const animalsAboveAverage = getAnimalsAboveAverage(histogram, analysisOriginal);
    const animalsBelowAverage = getAnimalsBelowAverage(histogram, analysisOriginal);
    const unit = getUnit(histogram, analysisOriginal);
    const average = getAverage(histogram, analysisOriginal);

    setAnimalsAboveAverage(animalsAboveAverage);
    setAnimalsBelowAverage(animalsBelowAverage);

    const percentageAnimalsAboveAverage = roundOneDecimal(animalsAboveAverage * 100 / analysisOriginal.resultData.larvaeNumber);
    const percentageAnimalsBelowAverage = roundOneDecimal(animalsBelowAverage * 100 / analysisOriginal.resultData.larvaeNumber);
    setPercentageAnimalsAboveAverage(percentageAnimalsAboveAverage);
    setPercentageAnimalsBelowAverage(percentageAnimalsBelowAverage);

    if (chart && showOptions) {
      chart.refreshHistogram(dataset, histogram, limits, frequency, average, unit);
    } else {
      const width = getWidth(refChart);
      const height = getHeight(refChart);


      chart = new HistogramD3(refChart.current, dataset, histogram, limits, frequency, average, width, height, stylesHistogram, unit);
    }

  }, [analysisOriginal, numberBins, histogram, showOptions, stylesHistogram]);

  const removeBin = () => {
    if (numberBins > 6 && histogram !== typeHistogram.PIGMENTATION) {
      const bin = numberBins - 1;
      dispatch(setBin(bin));
      setNumberBins(bin);
    }
  };

  const addBin = () => {
    if (numberBins < 10 && histogram !== typeHistogram.PIGMENTATION) {
      const bin = numberBins + 1;
      dispatch(setBin(bin));
      setNumberBins(bin);
    }
  };

  function renderOptions () {
    if (showOptions) {
      const disabledMin = numberBins === 6 || histogram === typeHistogram.PIGMENTATION;
      const disabledMax = numberBins === 10 || histogram === typeHistogram.PIGMENTATION;
      const disabledColorMin = disabledMin ? styles.disabled : '';
      const disabledColorMax = disabledMax ? styles.disabled : '';
      const idBinSubtract = disabledMin ? 'bin_disabled_subtract' : 'bin_subtract';
      const idBinAdd = disabledMax ? 'bin_disabled_add' : 'bin_add';

      return (
        <div className={styles.actions}>
          <Radio.Group className={styles.typeHistogram} value={histogram} onChange={(event) => setHistogram(event.target.value)} size='small'>
            <Radio.Button className={cx(styles.weight, histogram === typeHistogram.WEIGHT ? '' : styles.border)} value={typeHistogram.WEIGHT}>
              <div id='radio_button_weight'>{t('detail.weight')}</div>
            </Radio.Button>
            <Radio.Button className={cx(styles.length, histogram === typeHistogram.LENGTH ? '' : styles.border)} value={typeHistogram.LENGTH}>
              <div id='radio_button_length'>{t('detail.length')}</div>
            </Radio.Button>
            <Radio.Button
              className={cx(styles.pigmentation, histogram === typeHistogram.PIGMENTATION ? '' : styles.border)}
              value={typeHistogram.PIGMENTATION}>
              <div id='radio_button_pigmentation'>{t('detail.pigmentation')}</div>
            </Radio.Button>
          </Radio.Group>

          <Radio.Group className={styles.bins} size='small'>
            <Radio.Button className={cx(styles.remove, disabledColorMin)} onClick={removeBin}>
              <Icon id={idBinSubtract} name='subtract' />
            </Radio.Button>
            <Radio.Button className={cx(styles.add, disabledColorMax)} onClick={addBin}>
              <Icon id={idBinAdd} name='add' />
            </Radio.Button>
          </Radio.Group>
        </div>
      );
    }

    return null;
  }

  const renderAnimalsAboveBelowAverage = () => {
    return (
      <div className={containerAnimalsClassName}>
        <Tooltip title={t('detail.animalsBelowAverage')} className={animalsClassName}>
          <div>
            {animalsBelowAverage} {t(`analysis.resultData.${dictType}Sample`).toUpperCase()}
          </div>
          <div>
            {percentageAnimalsBelowAverage} %
          </div>
        </Tooltip>

        <Tooltip title={t('detail.animalsAboveAverage')} className={animalsClassName}>
          <div>
            {animalsAboveAverage} {t(`analysis.resultData.${dictType}Sample`).toUpperCase()}
          </div>
          <div>
            {percentageAnimalsAboveAverage} %
          </div>
        </Tooltip>
      </div>
    );
  };

  return <div id='histogram_chart' className={containerClassName}>
    {renderOptions()}
    {showAnimalsAboveBelowAverage() && renderAnimalsAboveBelowAverage()}

    <div ref={refChart} />
  </div>;
}
