import { roundWeight, getWeightUnit, weightUnits } from '../../config/commons';

import BarsChart from './BarsChart';
import styles from './Chart.module.scss';
import { Analysis } from './interfaces';
import { groupLabels } from './math-percentile';
import { getUnitCountAnimalChartByAnalysisType, getUnitCountAnimalChartByStockingType } from './helpers';

interface Props {
  analysisData: Analysis;
  typeChart: 'SHRIPMS_PER_GROUP' | 'CUMMULATIVE_WEIGHT_PER_GROUP';
  axisClassName?: string;
  lineTop?: string;
  textClassName?: string;
  textInsideBarClassName?: string;
  containerClassName?: string;
  typeStyles?: 'DEFAULT' | 'PDF';
}

function HorizontalBarsChart (props: Props) {
  const {
    axisClassName = styles.axis,
    lineTop = styles.lineTop,
    textClassName = styles.chartText,
    textInsideBarClassName = styles.chartText,
    containerClassName = styles.container,
    analysisData,
    typeChart,
    typeStyles = 'DEFAULT',
  } = props;

  const larvaeNumber = analysisData.resultData.larvaeNumber;
  const analysisType = analysisData.type;

  let data: number[] = [];
  let ranges: number[] = [];
  let percents: string[] = [];
  let averageWeight = 0;
  let weightLabels: string[] = [];

  let measureUnit = (analysisData.sowingId && analysisData.sowingId.phaseType) ?
    getUnitCountAnimalChartByStockingType(analysisData.sowingId.phaseType) :
    getUnitCountAnimalChartByAnalysisType(analysisType);

  if (typeChart === 'SHRIPMS_PER_GROUP') {
    percents = analysisData.resultData.countGroup.map(value => ((value * 100) / larvaeNumber).toFixed(1));
    data = analysisData.resultData.countGroup;
  } else if (typeChart === 'CUMMULATIVE_WEIGHT_PER_GROUP') {
    data = analysisData.resultData.weightGroup.map(group => Math.round(group));
    const totalWeight = data.reduce((total, value) => total + value, 0);

    percents = data.map(group => ((group * 100) / totalWeight).toFixed(1));
    measureUnit = weightUnits.MG;
  }

  ranges = analysisData.resultData.labels;
  averageWeight = analysisData.resultData.averageWeight;

  ranges = roundWeight({ value: ranges, showUnit: false, average: averageWeight });
  if (measureUnit !== 'pl' && measureUnit !== 'ind') {
    measureUnit = getWeightUnit(averageWeight);
    data = roundWeight({ value: data, showUnit: false, average: averageWeight });
  }

  weightLabels = groupLabels(ranges);

  return (
    <BarsChart
      data={data}
      weightLabels={weightLabels}
      measureUnit={measureUnit}
      isConsolidatedGrowOut={false}
      percents={percents}
      averageWeight={averageWeight}
      axisClassName={axisClassName}
      lineTop={lineTop}
      textClassName={textClassName}
      textInsideBarClassName={textInsideBarClassName}
      containerClassName={containerClassName}
      typeStyles={typeStyles}
    />
  );
}

export default HorizontalBarsChart;
